@import "components/fonts";
@import "components/media";
@import "components/clients";
*{
  box-sizing: border-box;
}
html{
  display: flex;
  min-height: 100%;
  body{
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: auto;
  }
}

.Brv-Header{
  display: flex;
  flex-direction: row;
  background-color: #fff;
  flex-basis: 100px;
  justify-content: center;
  padding-bottom:25px;
  &__constrainer{
    position:relative;
    display: flex;
    flex-direction: row;
    flex-basis: 75rem;
    padding: 0 20px;
    &__menu{
      display: flex;
      flex-basis:200px;
      align-items: flex-end;
      font-size:30px;
      cursor: pointer;
    }
    &__logo{
      display: flex;
      flex:auto;
      justify-content: center;
      align-items: flex-end;
      img {
        width:130px;
        height:35px;
      }
      a{
        line-height: 0;
      }
    }
    &__social{
      display: flex;
      flex-basis:200px;
      display:flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
      &__el{
        height:20px;
        width: 20px;
        margin-left: 10px;
        img{
          height:20px;
          width: 20px;
        }
        i{
          font-size: 20px;
        }
        a{
          &:link,&:visited{
            color:black;
          }
        }
      }
    }

    ul#menu{
      display: none;
      width:150px;
      margin: 0;
      position:absolute;
      top:130px;
      left:0;
      list-style:none;
      padding-left: 0;
      background-color: rgba(0,0,0,0.6);
      @include tablet {
        background-color: rgba(0,0,0,0.6);
      }
      @include desktop {
        background-color: rgba(0,0,0,0.6);
      }

      &>li{
        a{
          text-decoration: none;
          color:white;
          font-size: 15px;
          padding: 15px;
          display: block;
        }
        font-family:'Futura Std';
        font-weight: bold;
        letter-spacing: 2px;
        color:white;
        list-style:none;
        position:relative;
        cursor: pointer;
        &:hover{
          background-color: rgba(0,0,0,0.6);
          &>ul{
            display: block;
          }
        }
        &>ul{
          display: none;
          padding-left: 0;
          background-color: rgba(0,0,0,0.6);
          @include tablet {
            background-color: rgba(0,0,0,0.6);
          }
          @include desktop {
            background-color: rgba(0,0,0,0.6);
          }
          position:absolute;
          left:150px;
          top:0;
          &>li{
            a{
              font-size: 15px;
              padding: 15px;
              text-decoration: none;
              color:white;
              display: block;
            }
            cursor: pointer;
            list-style:none;
            &:hover{
              background-color: rgba(0,0,0,0.6);
            }
          }
        }
      }
    }

  }
}
.Brv-Content{
  display: flex;
  flex-direction: column;
  flex: auto;
  align-items: center;
  justify-content: center;
  background-size: auto 100%;
  background-position: top center;
  background-repeat: no-repeat;

  @include tablet {
    background-size: auto 100%;
    background-position: top center;
  }
  @include desktop {
    background-size: 100% auto;
    background-position: top center;
  }


  &__title{
    font-size: 30px;
    @include tablet {
      font-size: 75px;
    }
    @include desktop {
      font-size: 100px;
    }
    text-align: center;
    font-family:'Futura Std';
    font-weight: bold;
    text-transform: uppercase;
    padding: 35px 0 0 0;
    color:white;
    letter-spacing: 15px;
    &.dark{
      color:black;
    }
  }
  &__logo{
    padding: 35px 0;
    img{
      width:200px;
      height:200px;
      @include tablet {
        width:300px;
        height:300px;
      }
      @include desktop {
        width:400px;
        height:400px;
      }

    }
  }
  &__textBox{
    display: flex;
    flex-direction: column;
    font-family:'Futura Std';
    font-weight: 500;
    font-size: 13px;
    width:100%;
    max-width:75rem;

    align-items: center;
    justify-content: flex-start;
    padding-top: 40px;
    @include tablet {
      padding-top: 30px;
      justify-content: flex-start;
    }
    @include desktop {
      padding-top: 0;
      justify-content: center;
    }

    flex:auto;
    text-align: center;
    letter-spacing: 2px;
    &__text{
      display: flex;
      flex-direction: column;
      max-width: 550px;
      color:white;
      padding:0 20px;
      @include tablet {
        padding: 0;
      }
      @include desktop {
        padding: 0;
      }
      &.dark{
        color:black;
      }
    }
  }
  &__link{
    font-family:'Futura Std';
    font-weight: bold;
    font-size: 10px;
    color:white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width:150px;
    height:50px;
    background-image: url('/img/link@2x.png');
    background-position: top center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    margin:20px 0;
    text-decoration: none;
    text-transform: uppercase;
  }
}

