@font-face {
  font-family: 'Futura Std';
  src: url('../fonts/hinted-FuturaStd-Bold.eot');
  src: url('../fonts/hinted-FuturaStd-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/hinted-FuturaStd-Bold.woff') format('woff'),
  url('../fonts/hinted-FuturaStd-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Futura Std';
  src: url('../fonts/hinted-FuturaStd-Medium.eot');
  src: url('../fonts/hinted-FuturaStd-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/hinted-FuturaStd-Medium.woff') format('woff'),
  url('../fonts/hinted-FuturaStd-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TeXGyreSchola';
  src: url('../fonts/TeXGyreSchola-Regular.eot');
  src: url('../fonts/TeXGyreSchola-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TeXGyreSchola-Regular.woff2') format('woff2'),
  url('../fonts/TeXGyreSchola-Regular.woff') format('woff'),
  url('../fonts/TeXGyreSchola-Regular.ttf') format('truetype'),
  url('../fonts/TeXGyreSchola-Regular.svg#TeXGyreSchola-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}