@font-face {
  font-family: 'Futura Std';
  src: url("../fonts/hinted-FuturaStd-Bold.eot");
  src: url("../fonts/hinted-FuturaStd-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/hinted-FuturaStd-Bold.woff") format("woff"), url("../fonts/hinted-FuturaStd-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Futura Std';
  src: url("../fonts/hinted-FuturaStd-Medium.eot");
  src: url("../fonts/hinted-FuturaStd-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/hinted-FuturaStd-Medium.woff") format("woff"), url("../fonts/hinted-FuturaStd-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TeXGyreSchola';
  src: url("../fonts/TeXGyreSchola-Regular.eot");
  src: url("../fonts/TeXGyreSchola-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/TeXGyreSchola-Regular.woff2") format("woff2"), url("../fonts/TeXGyreSchola-Regular.woff") format("woff"), url("../fonts/TeXGyreSchola-Regular.ttf") format("truetype"), url("../fonts/TeXGyreSchola-Regular.svg#TeXGyreSchola-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

.Clients {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.Clients__client {
  margin: 20px;
  display: flex;
  flex-direction: column;
  width: 170px;
  height: 170px;
  background-color: #000000;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
}

.Clients__client__text {
  color: white;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}

* {
  box-sizing: border-box;
}

html {
  display: flex;
  min-height: 100%;
}

html body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: auto;
}

.Brv-Header {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  flex-basis: 100px;
  justify-content: center;
  padding-bottom: 25px;
}

.Brv-Header__constrainer {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-basis: 75rem;
  padding: 0 20px;
}

.Brv-Header__constrainer__menu {
  display: flex;
  flex-basis: 200px;
  align-items: flex-end;
  font-size: 30px;
  cursor: pointer;
}

.Brv-Header__constrainer__logo {
  display: flex;
  flex: auto;
  justify-content: center;
  align-items: flex-end;
}

.Brv-Header__constrainer__logo img {
  width: 130px;
  height: 35px;
}

.Brv-Header__constrainer__logo a {
  line-height: 0;
}

.Brv-Header__constrainer__social {
  display: flex;
  flex-basis: 200px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.Brv-Header__constrainer__social__el {
  height: 20px;
  width: 20px;
  margin-left: 10px;
}

.Brv-Header__constrainer__social__el img {
  height: 20px;
  width: 20px;
}

.Brv-Header__constrainer__social__el i {
  font-size: 20px;
}

.Brv-Header__constrainer__social__el a:link, .Brv-Header__constrainer__social__el a:visited {
  color: black;
}

.Brv-Header__constrainer ul#menu {
  display: none;
  width: 150px;
  margin: 0;
  position: absolute;
  top: 130px;
  left: 0;
  list-style: none;
  padding-left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

@media (min-width: 768px) and (max-width: 1023px) {
  .Brv-Header__constrainer ul#menu {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

@media (min-width: 1024px) {
  .Brv-Header__constrainer ul#menu {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

.Brv-Header__constrainer ul#menu > li {
  font-family: 'Futura Std';
  font-weight: bold;
  letter-spacing: 2px;
  color: white;
  list-style: none;
  position: relative;
  cursor: pointer;
}

.Brv-Header__constrainer ul#menu > li a {
  text-decoration: none;
  color: white;
  font-size: 15px;
  padding: 15px;
  display: block;
}

.Brv-Header__constrainer ul#menu > li:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.Brv-Header__constrainer ul#menu > li:hover > ul {
  display: block;
}

.Brv-Header__constrainer ul#menu > li > ul {
  display: none;
  padding-left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 150px;
  top: 0;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .Brv-Header__constrainer ul#menu > li > ul {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

@media (min-width: 1024px) {
  .Brv-Header__constrainer ul#menu > li > ul {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

.Brv-Header__constrainer ul#menu > li > ul > li {
  cursor: pointer;
  list-style: none;
}

.Brv-Header__constrainer ul#menu > li > ul > li a {
  font-size: 15px;
  padding: 15px;
  text-decoration: none;
  color: white;
  display: block;
}

.Brv-Header__constrainer ul#menu > li > ul > li:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.Brv-Content {
  display: flex;
  flex-direction: column;
  flex: auto;
  align-items: center;
  justify-content: center;
  background-size: auto 100%;
  background-position: top center;
  background-repeat: no-repeat;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .Brv-Content {
    background-size: auto 100%;
    background-position: top center;
  }
}

@media (min-width: 1024px) {
  .Brv-Content {
    background-size: 100% auto;
    background-position: top center;
  }
}

.Brv-Content__title {
  font-size: 30px;
  text-align: center;
  font-family: 'Futura Std';
  font-weight: bold;
  text-transform: uppercase;
  padding: 35px 0 0 0;
  color: white;
  letter-spacing: 15px;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .Brv-Content__title {
    font-size: 75px;
  }
}

@media (min-width: 1024px) {
  .Brv-Content__title {
    font-size: 100px;
  }
}

.Brv-Content__title.dark {
  color: black;
}

.Brv-Content__logo {
  padding: 35px 0;
}

.Brv-Content__logo img {
  width: 200px;
  height: 200px;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .Brv-Content__logo img {
    width: 300px;
    height: 300px;
  }
}

@media (min-width: 1024px) {
  .Brv-Content__logo img {
    width: 400px;
    height: 400px;
  }
}

.Brv-Content__textBox {
  display: flex;
  flex-direction: column;
  font-family: 'Futura Std';
  font-weight: 500;
  font-size: 13px;
  width: 100%;
  max-width: 75rem;
  align-items: center;
  justify-content: flex-start;
  padding-top: 40px;
  flex: auto;
  text-align: center;
  letter-spacing: 2px;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .Brv-Content__textBox {
    padding-top: 30px;
    justify-content: flex-start;
  }
}

@media (min-width: 1024px) {
  .Brv-Content__textBox {
    padding-top: 0;
    justify-content: center;
  }
}

.Brv-Content__textBox__text {
  display: flex;
  flex-direction: column;
  max-width: 550px;
  color: white;
  padding: 0 20px;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .Brv-Content__textBox__text {
    padding: 0;
  }
}

@media (min-width: 1024px) {
  .Brv-Content__textBox__text {
    padding: 0;
  }
}

.Brv-Content__textBox__text.dark {
  color: black;
}

.Brv-Content__link {
  font-family: 'Futura Std';
  font-weight: bold;
  font-size: 10px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 150px;
  height: 50px;
  background-image: url("/img/link@2x.png");
  background-position: top center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  margin: 20px 0;
  text-decoration: none;
  text-transform: uppercase;
}
