.Clients{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  &__client{
    margin: 20px;
    display: flex;
    flex-direction: column;
    width:170px;
    height:170px;
    background-color: #000000;
    justify-content: center;
    align-items: center;
    border-radius: 200px;
    &__text{
      color:white;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}